import ProductApiService from "../../service/productService";

export const getAllStatuses = (token) => async (dispatch) => {
  try {
    dispatch(updateLoading(true));
    const response = await ProductApiService.getItemStatuses(token);
    const { data } = response;
    const statuses = {};

    data.forEach((element) => {
      statuses[element.id] = element;
    });

    dispatch(setStatuses(statuses));
    dispatch(updateLoading(false));
    return statuses;
  } catch (error) {
    dispatch(updateLoading(false));
    console.warn(error);
  }
};

export const getItems = (token) => async (dispatch) => {
  try {
    dispatch(updateLoading(true));
    const response = await ProductApiService.getItems(token);
    const { data } = response;
    const items = {};

    data.forEach((element, index) => {
      index === 0 ? dispatch(getThumbnail(token, element.id)) : null;
      items[element.id] = element;
    });

    dispatch(setItems(items));
    dispatch(updateLoading(false));
    return items;
  } catch (error) {
    dispatch(updateLoading(false));
    console.warn(error);
  }
};

export const getThumbnail = (token, itemId) => async (dispatch) => {
  try {
    dispatch(updateLoading(true));

    const response = await ProductApiService.getThumbnail(token, itemId);
    const { data } = response;
    dispatch(setPreviewImage(data));
    dispatch(updateLoading(false));
    return data;
  } catch (error) {
    dispatch(updateLoading(false));
    console.warn(error);
  }
};

export const setPreviewImage = (previewImage) => {
  return {
    type: "SET_PREVIEW_IMAGE",
    previewImage,
  };
};

export const setItems = (items) => {
  return {
    type: "SET_ITEMS",
    items,
  };
};
export const updateLoading = (loading) => {
  return {
    type: "UPDATE_LOADING",
    loading,
  };
};
export const setStatuses = (statuses) => {
  return {
    type: "SET_STATUSES",
    statuses,
  };
};
