const defaultState = {
  statuses: {},
  loading: false,
  items: {},
  previewImage: null,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case "SET_STATUSES": {
      return {
        ...state,
        statuses: action.statuses,
      };
    }
    case "SET_PREVIEW_IMAGE": {
      return {
        ...state,
        previewImage: action.previewImage,
      };
    }
    case "SET_ITEMS": {
      return {
        ...state,
        items: action.items,
      };
    }
    case "UPDATE_LOADING": {
      return {
        ...state,
        loading: action.loading,
      };
    }
    default:
      return state;
  }
}
