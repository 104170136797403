import axios from "axios";
import { BACKEND_URL } from "../../constants";
import { LOGIN_URL } from "../../constants";
import { notification } from "antd";

const GET_METHOD = "get";

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 404) {
      if (err.response.request.responseURL.includes("thumbnail")) {
        return;
      }
      notification.open({
        message: "Not Found",
        description: err.response.data.message,
      });
    } else if (err.response.status === 400) {
      notification.error({
        message: "Bad Request",
        description: err.response.data.message,
      });
    } else if (err.response.status === 401) {
      notification.error({
        message: "Authentication Needed",
        description: `${err.response.data.message}. Try logging in again.`,
      });
      localStorage.clear();
      window.location.href = window.location.origin + LOGIN_URL;
    } else if (err.response.status === 403) {
      notification.error({
        message: "Bad Authentication",
        description: `${err.response.data.message}. Try logging in again.`,
      });
      localStorage.clear();
      window.location.href = window.location.origin + LOGIN_URL;
    } else if (err.response.status === 409) {
      notification.error({
        message: "Conflict",
        description: err.response.data.message,
      });
    } else {
      console.log(err);
      throw err;
    }
  }
);

class ProductApiService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }
  getItemStatuses = async (token) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/item_statuses`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  };
  getItems = async (token) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/items`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  };
  getThumbnail = async (token, itemId) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/item_thumbnails/${itemId}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  };
}

export default new ProductApiService();
