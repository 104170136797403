import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { isEmpty, keys, size, forEach } from "lodash";
import "./productSection.less";
import {
  Button,
  Card,
  Col,
  Image,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
const { Title, Text, Paragraph } = Typography;
import model from "../asset/model.png";

class ProductSection extends Component {
  constructor(props) {
    super(props);
    this.getModelStatues = this.getModelStatues.bind(this);
    this.showEmptyProductCard = this.showEmptyProductCard.bind(this);
  }

  getModelStatues() {
    let review = 0;
    forEach(this.props.items, (item) => {
      item.current_status_id === 2 ? review++ : review;
    });
    return review;
  }

  showEmptyProductCard() {
    return (
      <Row justify="left" align="center" gutter={24}>
        <Col xl={4} lg={8}>
          <Image width={133} height={"100%"} preview={false} src={model} />
        </Col>
        <Col span={20}>
          <Title level={4}>Your 3D Models</Title>
          <Space direction={"vertical"}>
            <Paragraph>
              Intro copy to creating 3D models. Repeated on the model landing
              page which gives a quick overview of the process for creating 3d
              models
            </Paragraph>
            <Paragraph>
              <Text strong>Next Steps:</Text>
            </Paragraph>
          </Space>
          <Paragraph>
            Upload your own 3D peoduct files or have Bitreel create photoreal
            products from your design files or referece materials.
          </Paragraph>
          <a href="/products">
            <Button className="home_primary_button" type="primary">
              GET STARTED
            </Button>
          </a>
        </Col>
      </Row>
    );
  }

  showProductCard() {
    let reviewmodels = this.getModelStatues();
    return (
      <Row justify="start" align="center" gutter={24}>
        <Col xl={6} lg={8}>
          <Image
            preview={false}
            width={133} height={"100%"}
            src={
              this.props.previewImage ? this.props.previewImage.thumbnail : model
            }
            fallback={model}
          />
        </Col>
        <Col xl={18} lg={16}>
          <Title level={4}>Your 3D Models</Title>
          <Space direction={"vertical"}>
            <Paragraph>
              You currently have {<Text strong>{size(this.props.items)}</Text>}{" "}
              {size(this.props.items) === 1 ? "model" : "models"} in your
              library.{" "}
              <a href="/products">
                <Text underline>Manage models</Text>
              </a>
            </Paragraph>
            <Paragraph>
              <Text strong>Next Steps:</Text>
            </Paragraph>
          </Space>
          <Paragraph>
            {" "}
            You have {reviewmodels} {reviewmodels === 1 ? "model" : "models"}{" "}
            ready for your approval{" "}
          </Paragraph>
        </Col>
      </Row>
    );
  }
  render() {
    return (
      <div>
        {this.props.loading ? (
          <Row justify="center" align="center">
            <Card className="loading_card">
              <Skeleton active />
            </Card>
          </Row>
        ) : (
          <>
            <Row justify="center" align="center">
              <Card className="product_card">
                {isEmpty(this.props.items)
                  ? this.showEmptyProductCard()
                  : this.showProductCard()}
              </Card>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    items: state.products.items,
    loading: state.products.loading,
    previewImage: state.products.previewImage,
  };
};

export default connect(mapStateToProps, {})(withRouter(ProductSection));
