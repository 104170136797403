import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Button, Card, Divider, Layout, Menu, Typography } from "antd";
const { Paragraph, Text } = Typography;

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
import "./sidermenu.less";

class SiderMenu extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Sider className="home_side_nav">
        <Card bordered={false}>
          <h2>Welcome</h2>
          <p>{this.props.auth().user.email}</p>
          {/*<Divider />*/}
          {/*<Paragraph>*/}
          {/*  <Text strong>Your Plan: </Text>TBD*/}
          {/*</Paragraph>*/}
          {/*<Button*/}
          {/*  className="home_button"*/}
          {/*  disabled={true}*/}
          {/*  shape="round"*/}
          {/*  size="middle"*/}
          {/*  onClick={() => {}}*/}
          {/*>*/}
          {/*  UPGRADE PLAN*/}
          {/*</Button>*/}
        </Card>
      </Sider>
    );
  }
}

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps, {})(withRouter(SiderMenu));
