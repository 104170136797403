import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Layout } from "antd";
import { LOGIN_URL, NO_AUTH_URLS } from "../constants";
import { sharedInterface } from "@Bitreel/portal-login";
import SiderMenu from "./sidermenu";
const { Content } = Layout;

import "./mainContainer.less";
import ProductSection from "./productSection";
import BannerSection from "./bannerSection";

import { getItems } from "./redux/actions/products";

class MainContainer extends Component {
  constructor(props) {
    super(props);
    const { auth } = this.props;
    if (!auth().token && !NO_AUTH_URLS.includes(window.location.pathname)) {
      window.location.href = window.location.origin + LOGIN_URL;
    }
    this.props.getItems(this.props.auth().token);
  }

  render() {
    const { auth } = this.props;

    return (
      <>
        <Layout style={{ paddingTop: "60px" }}>
          <SiderMenu auth={auth} />
          <Layout style={{ marginLeft: 200 }}>
            <Content className="home_content">
              <BannerSection />
              <ProductSection />
            </Content>
          </Layout>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: sharedInterface,
    previewItem: state.products.items[0],
  };
};

export default connect(mapStateToProps, {
  getItems,
})(withRouter(MainContainer));
