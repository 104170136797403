import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import "./productSection.less";
import { Row, Typography } from "antd";
const { Title, Paragraph } = Typography;

class BannerSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        {this.props.loading ? (
          <Row justify="center" align="center">
            <Title style={{ color: "white" }} level={3}>
              LOADING...
            </Title>
          </Row>
        ) : (
          <Row justify="center" align="center">
            {isEmpty(this.props.items) ? (
              <>
                <Typography>
                  <Row justify="center" align="center">
                    <Title style={{ color: "white" }} level={3}>
                      Let's get your showroom online
                    </Title>
                  </Row>
                  <Row justify="center" align="center">
                    <Paragraph style={{ color: "white" }}>
                      We'll guide you step by step on how to build your virtual
                      showroom, get your products ready and curated, then share
                      it with the world
                    </Paragraph>
                  </Row>
                </Typography>
              </>
            ) : (
              <Title style={{ color: "white" }} level={3}>
                3D Models
              </Title>
            )}
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    items: state.products.items,
    loading: state.products.loading,
  };
};

export default connect(mapStateToProps, {})(withRouter(BannerSection));
